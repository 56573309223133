.profile-container {
    margin-top: 0.5em;
}

.profile-container .dropdown-toggle {
    display: none;
}

.profile-container.active {
    background: #eee;
}

.profile-container .dropdown,
.profile-container .dropdown-menu {
    width: 100%;
}

.profile-picture {
    float: left;
}

.profile-picture > img {
    width: 2em;
    height: 2em;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.profile-info {
    display: inline-block;
    margin-left: 0.5em;
    cursor: pointer;
}

.profile-container:hover {
    background: #eee;
    border-radius: 0.1em;
}

.profile-info small {
    display: block;
}

.profile-info h6 {
    margin: 0;
}

.hide-arrow::after {
    display:none;
}

.hand {
    cursor:pointer;
}