.selectorContent {
  min-width: 160px;
  border: 1px solid #999;
  border-radius: .25rem;
  background-color: #fff; 
}

.selectorContent .header {
  background-color: #ddd; 
  border-bottom: 1px solid #aaa;
  padding: 5px;
}

.selectorContent .selectors {
  display: inline-block;
  padding: 5px;
}

.selectorContent .selectors .availability {
  display: inherit;
  margin: 0 1px 0 1px;
  border: 2px solid transparent;
}

.selectorContent .selectors .availability.selected {
  border-color: inherit;
}