.scheduleGridHeaderRow {
  margin-bottom: 15px;
}

.scheduleGridHeader {
  font-weight: 600;
  font-size: 1.5em;
}

.scheduleGridHeader svg {
  margin-right: 5px;
  padding-top: 3px;
}

.scheduleGridHeaderRow .icon {
  text-align: right;
  cursor: pointer;
}