.schGridHeader {
  font-weight: 700;
}

.schDate {
  font-size: 0.8em;
}

.grpSchContainer {
  margin-bottom: 20px;
  border: 1px solid #e9ecef;
  min-width: 900px;
}

.headerRow {
  border-bottom: 1px solid #e9ecef;
}

.overflowContainer {
  overflow-x: auto;
}

.weekend {
  background-color: #eee;
}