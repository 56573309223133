.timezone {
  font-size: 0.65em;
  padding-top: 5px;
  padding-left: 5px;
}

.individualRow {
  border-bottom: 1px solid #e9ecef;
}

.scheduleName {
  font-size: 0.9rem;
  font-weight: 600;
  padding-left: 5px;
  padding-top: 5px;
}

.skillContainer {
  padding-left: 5px;
}

.skill {
  width: 20px;
  border-radius: 0.3em;
}

.skill-w {
  color: #fff;
  background-color: #007bff;
}

.skill-v {
  color: #111;
  background-color: #ffc107;
}

.skill-s {
  color: #fff;
  background-color: #28a745;
}

.skill-100 {
  width: 100%;
}