.link-alignment {
    margin-top: -10px;
}

.img-link {
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;
}

.img-android {
    width: 152px;
}

.img-ios {
    width: 135px;
}